.modal {
  display: none;
  position: relative;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s all;
  .overlay {
    display: none;
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #00000040;
    backdrop-filter: blur(5px);
    transition: 0.4s all;
  }

  .modalCard {
    display: none;
    position: relative;
    padding: 24px;
    background-color: #141316;
    z-index: 15;
    border-radius: 12px;
    width: 400px;
    border: 1px solid #ffffff10;
    transition: 0.4s all;
  }
  .cardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    .cardHeaderRight {
      display: flex;
      align-items: center;
      img {
        height: 40px;
        width: 40px;
        margin-right: 16px;
        border-radius: 100px;
      }
      h3 {
        margin-bottom: 4px;
      }
      p {
        color: #ffffff60;
        font-size: 14px;
      }
    }
    .closebtn {
      color: #fff;
      border: 1px solid #ffffff10;
    }
  }

  .cardContent {
    .RangeSlider {
      margin-bottom: 40px;
      .Slider {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          font-size: 12px;
          color: #ffffff80;
        }
        p:nth-child(2) {
          padding: 8px;
          background-color: #ffde91;
          color: #000000;
          border-radius: 100px;
        }
      }
    }
    .titleSection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      background-color: #171719;
      border-radius: 4px;
      p {
        font-size: 14px;
      }
      p:nth-child(1) {
        color: #ffffff60;
        font-size: 12px;
      }
      p:nth-child(2) {
        font-weight: 500;
      }
      margin-bottom: 8px;
    }
    .titleSection:nth-child(5) {
      margin-bottom: 0px !important;
    }
  }
}

.show_Overlay {
  display: flex !important;
  position: fixed !important;
  transition: 0.4s all;
}

.show_Overlay_card {
  display: block !important;
  position: fixed !important;
  animation-duration: 0.4s !important;
  animation-name: zoomIn !important;
}

div[name="HeaderPanel_CloseMenuPanel__2l3rS"] {
  animation-duration: 0.7s !important;
  animation-name: zoomOut !important;
}

@media (max-width: 426px) {
  .modalCard {
    position: absolute;
    padding: 24px;
    background-color: #141316;
    z-index: 15;
    border-radius: 12px;
    left: 24px;
    right: 24px;
    width: auto !important;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
