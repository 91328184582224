.Header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 24px 0px;
}

.SearchSection {
  align-items: center !important;
  height: 56px;
  width: 56px;

  border: 1px solid #ffffff10 !important;
  color: #ffffff !important;
}

.UserSection {
  align-items: center !important;
  border: 1px solid #ffffff10 !important;
  color: #ffffff !important;
  border-radius: 100px;
  padding: 14px;
}
