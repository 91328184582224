.Titles {
  margin-bottom: 16px;
}

.tagtime {
  text-transform: none !important;
  border: 1px solid #ffffff10 !important;
  border-radius: 8px !important;
  padding: 16px;
  color: #ffffff !important;
}

.tagtime_active {
  background-color: #ffde91 !important;
  color: #000 !important;
}

.TrendingMarket {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.Select {
  width: 100px;
  height: 30px;
  border-radius: 8px;
  border: 1px solid #ffffff10;
  background-color: transparent;
  padding: 4px;
  color: #ffffff !important;
  option {
    color: #000;
    background-color: #fff;
    border: 1px solid #ffffff10;
  }
}
