* {
  margin: 0px;
  padding: 0px;
}
html {
  background-color: #141316;
}
body {
  /* padding: 24px 0px; */

  background-color: #141316;
  color: #fff;
}

a {
  text-decoration: none;
  color: #ffffff;
  border-radius: 100px;
}

a:active {
  color: transparent !important;
}
