.searchContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: #f9f9f9;
  background-color: #171719;
  border-radius: 24px;
  // height: 250px;
  padding: 16px 16px;
  margin-bottom: 40px;

  .searchInput {
    position: relative;
    border-radius: 12px;
    padding: 24px 16px;
    outline: none;
    background-color: #141316;
    color: #fff;
    border: 1px solid #ffffff10;
    width: 500px;
    max-width: 100%;
  }
  .searchClear {
    position: absolute;
    color: #fff;
    right: 24px;
  }
}

@media (min-width: 768px) {
  .searchContainer {
    .searchClear {
      position: absolute;
      color: #fff;
      right: 104px;
    }
  }
}

@media (min-width: 1024px) {
  .searchContainer {
    .searchClear {
      position: absolute;
      color: #fff;
      right: 234px;
    }
  }
}

@media (min-width: 1440px) {
  .searchContainer {
    .searchClear {
      position: absolute;
      color: #fff;
      right: 322px;
    }
  }
}
