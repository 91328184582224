.card {
  .CardActionArea {
    padding: 16px;
    border-radius: 12px;
  }
  background-color: #171719;
  border-radius: 12px;
  .topSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    p:nth-child(1) {
      color: #ffffff60;
      font-size: 14px;
      margin-bottom: 12px;
    }
    p:nth-child(2) {
      color: #ffffff;
      font-size: 24px;
      font-weight: 500;
    }
    .priceChanges {
      margin-top: 12px;
      display: flex;
      align-items: center;
      p {
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
      }
    }
    img {
      height: 50px;
      border-radius: 100px;
    }
  }

  .bottomSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .percentChange {
      display: flex;
      align-items: center;
      svg {
        margin-right: 8px;
      }
    }
    .percentChangeUP {
      color: #3ab49d;
      svg {
        margin-right: 8px;
        fill: #3ab49d;
      }
    }

    .percentChangeDOWN {
      color: #fb7870;
      svg {
        margin-right: 8px;
        fill: #fb7870;
      }
    }
  }
}
