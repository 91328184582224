// #ffde91

.card {
  border-bottom: 1px solid #ffffff05;
  .CardActionArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px;
    border-radius: 8px;
  }
  .leftSection {
    display: flex;
    align-items: center;
    img {
      height: 50px;
      margin-right: 12px;
      border-radius: 100px;
    }
    .cardName {
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 8px;
    }
    .cardSymbol {
      font-size: 14px;
      color: #ffffff60;
    }
  }

  .cardPercentAge {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    svg {
      margin-right: 8px;
    }
  }

  .rightSection {
    text-align: end;
    align-items: flex-start;
    .cardName {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 8px;
    }
    .cardPercentagePos {
      font-size: 14px;
      color: #3ab49d;
      fill: #3ab49d;
    }
    .cardPercentageNeg {
      font-size: 14px;
      color: #fb7870;
      fill: #fb7870;
    }
  }
}

.cardShimmer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;
  border-radius: 8px;
  border-bottom: 1px solid #ffffff05;
  .leftSection {
    display: flex;
  }
  .rightSection {
    text-align: end;
    align-items: flex-end;
  }
}
